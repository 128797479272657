.page-wrapper {
  .page-header {
    .back-btn {
      display: flex;
      gap: 10px;
      .page-display {
        a {
          padding: 7px;
          background-color: $secondary;
          color: $white;
          display: flex;
          align-items: center;
          gap: 8px;
          border-radius: 8px;
        }
      }
    }
  }
}
.card {
  .card-body {
    &.add-product {
      .addproduct-icon {
        @include margin-padding(0 0 20px 0px, 0 0 20px 0);
        border-bottom: 1px solid rgba(145, 158, 171, 0.3);
        display: flex;
        justify-content: space-between;
        width: 100%;
        cursor: pointer;
        h5 {
          font-size: $font-size-16;
          font-weight: $font-weight-semibold;
          .add-info {
            color: $primary;
            margin-right: 7px;
          }
        }
      }
      .text-editor {
        &.add-list {
          padding-top: 10px;
          width: 100%;
          h5 {
            padding-left: 0;
          }
          &.add {
            padding-top: 10px;
            .addproduct-icon {
              padding-left: 0;
            }
            .image-upload {
              margin-bottom: 0;
              width: 137px;
              height: 123px;
              border: 1px dashed rgba(145, 158, 171, 0.32);
              .image-uploads {
                padding: 43px 0;
              }
            }
            .custom-filed {
              display: flex;
              padding: 10px;
              background-color: $body-bg;
              margin-bottom: 20px;
              .input-block {
                &.add-lists {
                  display: flex;
                  gap: 20px;
                  align-items: center;
                  @include respond-below(custom767) {
                    flex-wrap: wrap;
                  }
                  .checkboxs {
                    margin-bottom: 0;
                    padding-left: 23px;

                    .checkmarks {
                      top: 3px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.input-blocks.add-product input[type="text"] {
  padding: 6px 15px;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  @include rounded(5px);
}
// .add-product input {
//     height: 40px;
// }
.input-block.add-product.list {
  position: relative;
}
.btn-primaryadd {
  position: absolute;
  top: 32px;
  right: 4px;
  padding: 5px 10px;
  background-color: $primary;
  font-size: $font-size-14;
  color: $white;
  &:hover {
    border: 1px solid $primary;
    color: $primary;
  }
}
.input-block input.form-control.list {
  color: #878a99;
}
.edit-add {
  h6 {
    font-size: $font-size-16;
  }
}
.edit-add {
  font-size: $font-size-15;
  &.card {
    border: none;
    margin: 0;
    .note-editor {
      &.note-frame {
        margin: 0px;
        .note-editing-area {
          .note-editable {
            height: 130px !important;
          }
        }
      }
    }
    p {
      margin-top: 10px;
    }
  }
}
.input-blocks.add-products .form-check-input:checked {
  background-color: $primary;
  border-color: $primary;
}
.input-blocks.add-product.date input[type="text"] {
  padding: 6px 6px 6px 39px;
}
.input-blocks.add-product.date .input-groupicon .addonset {
  left: 12px;
  top: 48%;
}
.btn-addproduct {
  float: right;
}
.addproduct-icon a {
  color: $secondary;
  border: 1px solid;
  padding: 1px;
  border-radius: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  width: 20px;
  height: 20px;
  svg {
    height: 17px;
  }
}
.card .card-body.add-product .addproduct-icon.list {
  padding-left: 0;
}
.add-newplus {
  display: flex;
  justify-content: space-between;
}
.plus-down-add {
  margin-right: 5px;
  width: 14px;
  height: 14px;
  position: relative;
  top: -2px;
}
.add-newplus {
  span {
    font-size: $font-size-14;
    font-style: normal;
    font-weight: $font-weight-bold;
  }
}
.add-choosen {
  display: flex;
  @include respond-below(custom767) {
    flex-wrap: wrap;
  }
  .phone-img {
    position: relative;
    width: 137px;
    height: 123px;
    border-radius: 10px;
    border: 1px solid var(--Stroke, rgba(145, 158, 171, 0.3));
    text-align: center;
    margin-left: 20px;
    @include respond-below(custom767) {
      margin-bottom: 10px;
      margin-left: 0;
    }
    img {
      padding: 10px;
    }
    a {
      position: absolute;
      right: 3px;
      top: 3px;
      background: $danger;
      border-radius: 3px;
      width: 16px;
      height: 16px;
      color: $white;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      justify-content: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      &:hover {
        color: $white;
        background: #e61414;
      }
    }
  }
}
.custom_radio,
.custom_radio_one {
  color: $text-color;
  position: relative;
  font-size: $font-size-15;
  padding-left: 25px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  font-weight: $font-weight-normal;
}
.custom_radio input,
.custom_radio_one input {
  position: absolute;
  opacity: 0;
  padding: 0;
}
.custom_radio .checkmark,
.custom_radio_one .checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: transparent;
  border: 1px solid rgba(145, 158, 171, 0.3);
  border-radius: 50%;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.custom_radio .checkmark:after,
.custom_radio_one .checkmark:after {
  display: block;
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  opacity: 0;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: $primary;
  visibility: visible;
  transform: scale(0.1);
  -webkit-transform: scale(0.1);
  -ms-transform: scale(0.1);
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.custom_radio.active input ~ .checkmark:after,
.custom_radio_one.active input ~ .checkmark:after {
  transform: scale(1);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  opacity: 1;
  visibility: visible;
}
.custom_radio.active input ~ .checkmark {
  border: 1px solid $primary;
}

.single-pill-product {
  .nav-link {
    padding: 0;
  }
  .nav-pills.list .nav-item button::before {
    top: 2px;
    left: 0px;
  }
  .nav-pills.list .nav-item button.active::after {
    top: 6px !important;
    left: 4px !important;
  }
  .form-check-label {
    cursor: pointer;
  }
}
.nav-pills.list .nav-link.active {
  background-color: transparent;
  color: $text-color;
}
.nav-pills.list .nav-link {
  background-color: transparent;
  color: $text-color;
}
.add-icon.tab a {
  background-color: $secondary;
  color: $white;
}
.nav-pills.list .nav-item button {
  position: relative;
}
.nav-pills.list .nav-item button::before {
  content: "";
  width: 15px;
  height: 15px;
  border: 1px solid #e0e0e0;
  border-radius: 50%;
  position: absolute;
  top: 11px;
  left: 12px;
}
.nav-pills.list .nav-item button.active::after {
  content: "";
  width: 7px;
  height: 7px;
  background: $primary;
  border-radius: 50%;
  position: absolute;
  top: 15px;
  left: 16px;
}
.nav-pills.list .nav-item button.active::before {
  border-color: $primary;
}
.add-icon.tab-icon .feather-trashs {
  background-color: $white;
  color: $danger;
}
.add-icon.tab-icon a {
  background-color: $white;
  font-size: 11px;
  width: 34px;
  padding: 0;
}
.input-blocks {
  &.filter-add {
    margin-right: 0px;
    margin-bottom: 0px;
    .input-blocks {
      &.add-product {
        margin-bottom: 20px;
        margin-right: 7px;
      }
    }
  }
}
.addproduct-icon.list.icon h5 img {
  position: relative;
  top: 5px;
  padding-right: 5px;
}
#filter_inputs .table-responsive.product-list input {
  padding-left: 10px;
}
table tbody td.action-table-data a .add-checkbox {
  border: none;
  padding: 0;
}
.feather-pluses {
  color: $secondary;
  width: 14px;
  height: 14px;
}
.checkboxs.add-listcheck {
  padding-left: 24px;
}
.searchinputs.list input {
  width: 357px;
  position: relative;
  padding: 10px 31px 10px 10px;
  border: 1px solid var(--Stroke, rgba(145, 158, 171, 0.3));
  background: $white;
}
.feather-16.icon {
  position: absolute;
  top: 10px;
  right: 10px;
}
.modal-footer-btn.popup {
  margin-top: 0;
}
.search-dropdown.idea {
  height: auto;
}
.badges-warning {
  color: $warning;
  padding: 2px 10px;
  border-radius: 8px;
  border: 1px solid $warning;
  font-size: $font-size-14;
  font-weight: $font-weight-semibold;
  min-width: 80px;
}
.badge.badge-linedangered {
  color: $danger;
  padding: 2px 10px;
  border-radius: 8px;
  border: 1px solid $danger;
  font-size: $font-size-14;
  font-weight: $font-weight-semibold;
  min-width: 80px;
}
.input-blocks .info-imgs {
  position: absolute;
  top: 14px;
  z-index: 100;
  width: 16px;
  height: 16px;
  left: 5px;
  margin-left: 5px;
  color: $text-color;
}
.add-product {
  .add-icon a {
    background-color: $secondary;
    color: $white;
    @include respond-below(custom767) {
      z-index: 1;
    }
    &:hover {
      background-color: $primary;
    }
  }
}
.new-employee-field.product .card-title-head h6 {
  font-weight: $font-weight-semibold;
  font-size: $font-size-16;
  align-items: center;
  margin: 0;
  padding: 0;
  border-bottom: none;
}
.new-employee-field.product .card-title-head {
  margin: 0 0 20px;
  padding: 0 0 20px;
  border-bottom: 1px solid rgba(145, 158, 171, 0.3);
  display: flex;
  justify-content: space-between;
}
.new-employee-field.product .card-title-head a {
  color: #878a99;
  border: 1px solid;
  padding: 3px;
  border-radius: 25px;
}
.accordion-card-one {
  .accordion-item {
    border: 0;
    .accordion-button {
      padding: 0;
      box-shadow: none;
      .addproduct-icon {
        h5 {
          display: flex;
          align-items: center;
          svg {
            width: 16px;
            height: 16px;
            top: unset;
          }
        }
      }
      &::after {
        display: none;
      }
      &:not(.collapsed) {
        background-color: $white;
        .accordion-item {
          margin-bottom: 40px;
        }
      }
      &.collapsed {
        .addproduct-icon {
          a {
            svg {
              transform: rotate(180deg);
            }
          }
        }
      }
    }
    .accordion-body {
      padding: 0;
    }
  }
}
.selected-hide-color {
  display: none;
}
.selected-color-add {
  .selected-hide-color {
    display: flex;
    align-items: center;
  }
}
.variant-table {
  display: none;
}
.variant-select {
  height: 40px;
}
.variant-table {
  padding: 0;
  background: transparent;
  div.dataTables_wrapper div.dataTables_paginate {
    display: none;
  }
  .dataTables_filter,
  .dataTables_length {
    display: none;
  }
}
